import React from 'react';
import * as Sentry from '@sentry/node';
import { useRouter } from 'next/router';
import {
  Category,
  Project,
  Requirement,
  Subcategory,
  Supplier,
  User,
} from '../models';
import {
  useSuppliers,
  useExecutives,
  useProjectManagers,
  useFirestoreQuery,
} from '../hooks';
import firebase from '../lib/firebase/client';

const emptyPromise = new Promise<null>((resolve) => resolve(null));

function getDataFromFirebase(
  doc: firebase.firestore.DocumentData
): Array<Promise<any>> {
  const promises: Array<Promise<any>> = [];
  if (doc?.client) {
    promises.push(
      firebase.firestore().collection('/clients').doc(doc?.client).get()
    );
  } else {
    promises.push(emptyPromise);
  }
  if (doc?.executive) {
    promises.push(
      firebase.firestore().collection('/users').doc(doc?.executive).get()
    );
  } else {
    promises.push(emptyPromise);
  }
  if (
    doc?.projectManagers &&
    Array.isArray(doc?.projectManagers) &&
    doc?.projectManagers?.length > 0
  ) {
    promises.push(
      firebase
        .firestore()
        .collection('/users')
        .where('id', 'in', doc?.projectManagers)
        .get()
    );
  } else {
    promises.push(emptyPromise);
  }
  return promises;
}

function buildRequirements(
  doc: firebase.firestore.DocumentData,
  categories: Category[],
  subcategories: Subcategory[],
  suppliers: Supplier[]
): Requirement[] {
  return doc?.requirements?.map((requirement: Requirement) => {
    const category =
      categories?.find(
        (cat) => String(cat?.id) === String(requirement?.category)
      ) ?? {};
    const subcategory =
      subcategories?.find(
        (_subcategory) =>
          String(_subcategory?.id) === String(requirement?.subcategory)
      ) ?? {};
    const _suppliers =
      suppliers?.filter(
        (supplier) =>
          requirement?.suppliers?.filter(
            (_id: string) => String(supplier?.id) === String(_id)
          )?.length > 0
      ) ?? [];
    const quotes =
      requirement?.quotes?.map((quote) => {
        if (!quote?.budgetId) {
          const budget = quote?.budgets?.find((_budget) => _budget.selected);
          quote.budgetId = budget?.id ?? null;
        }
        return quote;
      }) ?? [];
    return {
      ...requirement,
      category,
      subcategory,
      suppliers: _suppliers,
      quotes,
    };
  });
}

interface TProjectContext {
  project?: Project;
  setProject?: React.Dispatch<React.SetStateAction<Project>>;
  categories?: Category[];
  suppliers?: Supplier[];
  subcategories?: Subcategory[];
  executives?: User[];
  projectManagers?: User[];
}

export const ProjectContext = React.createContext<TProjectContext>({});

interface ProjectContextProviderProps {
  children?: React.ReactNode;
}

export function ProjectContextProvider({
  children,
}: ProjectContextProviderProps) {
  const router = useRouter();
  const [project, setProject] = React.useState<Project | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<Error | null>(null);
  const [categories, setCategories] = React.useState<Category[]>([]);
  const [subcategories, setSubcategories] = React.useState<Subcategory[]>([]);
  const {
    data: dataExecutives,
    loading: loadingExecutives,
    error: errorExecutives,
  } = useExecutives();
  const {
    data: dataProjectManagers,
    loading: loadingProjectManagers,
    error: errorProjectManagers,
  } = useProjectManagers();

  const {
    data: suppliers,
    loading: loadingSuppliers,
    error: errorSuppliers,
  } = useSuppliers();

  const {
    data: _categories,
    status: categoriesStatus,
    error: categoriesError,
  } = useFirestoreQuery<any[]>(firebase.firestore().collection('/categories'));

  const {
    data: _subcategories,
    status: subcategoriesStatus,
    error: subcategoriesError,
  } = useFirestoreQuery<any[]>(
    firebase.firestore().collection('/subcategories')
  );

  React.useEffect(() => {
    if (categoriesStatus === 'success' && subcategoriesStatus === 'success') {
      setCategories(_categories?.map((doc) => Category.factory(doc)) ?? []);
      setSubcategories(
        _subcategories?.map((doc) => Subcategory.factory(doc)) ?? []
      );
    }
  }, [_categories, categoriesStatus, _subcategories, subcategoriesStatus]);

  React.useEffect(() => {
    const query = async (id: string) => {
      try {
        firebase
          .firestore()
          .collection('/projects')
          .doc(id)
          .onSnapshot(
            async (snapshot) => {
              const doc = snapshot.data();
              const [
                clientRef,
                executiveRef,
                projectManagersRef,
              ] = await Promise.all([...getDataFromFirebase(doc)]);
              const requirements = buildRequirements(
                doc,
                categories,
                subcategories,
                suppliers
              );
              const attention =
                clientRef
                  .data()
                  ?.contactPeople?.find(
                    (person) => String(person.id) === String(doc.attention)
                  ) ?? null;
              setProject(
                new Project({
                  ...doc,
                  client: clientRef.data(),
                  executive: executiveRef?.data(),
                  projectManagers: projectManagersRef?.docs?.map((pm) =>
                    pm?.data()
                  ),
                  attention,
                  requirements,
                })
              );
              setLoading(false);
            },
            (err) => {
              setError(new Error(err.message));
              setLoading(false);
            }
          );
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(new Error(err.message));
        } else {
          setError(new Error(String(err)));
        }
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    setProject(null);
    setError(null);
    if (router && router.asPath) {
      if (router.asPath.includes('projects')) {
        if (router?.query?.id && !Array.isArray(router?.query?.id)) {
          query(router?.query?.id);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [categories, router, subcategories, suppliers]);

  React.useEffect(
    function reportToSentry() {
      if (error) {
        Sentry.captureException(error);
      }
    },
    [error]
  );

  return (
    <ProjectContext.Provider
      value={{
        project,
        setProject,
        categories: categories
          ?.slice()
          ?.sort((a, b) => (a?.name > b?.name ? 1 : -1)),
        subcategories: subcategories
          ?.slice()
          ?.sort((a, b) => (a?.name > b?.name ? 1 : -1)),
        suppliers: suppliers
          ?.slice()
          ?.sort((a, b) => (a?.supplier > b?.supplier ? 1 : -1)),
        executives: dataExecutives,
        projectManagers: dataProjectManagers,
      }}
    >
      {loading || loadingExecutives || loadingProjectManagers ? (
        <div className="w-screen h-screen bg-primary-500" />
      ) : (
        children
      )}
    </ProjectContext.Provider>
  );
}
