import { useState, useEffect } from 'react';
import { Category, Subcategory, Supplier } from '../models';
import firebase from '../lib/firebase/client';
import useFirestoreQuery from './useFirestoreQuery';

export default function useSupplier(id: string) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Supplier>(null);
  const [error, setError] = useState<Error>(null);
  const { data: doc, status, error: errorSupplier } = useFirestoreQuery(
    firebase.firestore().collection('/suppliers').doc(`/${id}`)
  );
  const {
    data: categories,
    status: statusCategories,
    error: errorCategories,
  } = useFirestoreQuery<any[]>(
    // eslint-disable-next-line no-nested-ternary
    doc?.categories?.length > 0
      ? doc
        ? firebase
            .firestore()
            .collection('/categories')
            .where(
              'id',
              'in',
              doc?.categories?.map((category) => String(category)) ?? []
            )
        : null
      : null
  );
  const {
    data: subcategories,
    status: statusSubcategories,
    error: errorSubcategories,
  } = useFirestoreQuery<any[]>(
    // eslint-disable-next-line no-nested-ternary
    doc?.subcategories?.length > 0
      ? doc
        ? firebase
            .firestore()
            .collection('/subcategories')
            .where(
              'id',
              'in',
              doc?.subcategories?.map((subcategory) => String(subcategory)) ??
                []
            )
        : null
      : null
  );

  useEffect(() => {
    if (
      status === 'success' &&
      (statusCategories === 'success' || statusCategories === 'idle') &&
      (statusSubcategories === 'success' || statusSubcategories === 'idle')
    ) {
      const _categories =
        categories?.map((category) => Category.factory(category)) ?? [];
      const _subcategories =
        subcategories?.map((subcategory) => Subcategory.factory(subcategory)) ??
        [];
      const supplier = Supplier.factory({
        ...doc,
        categories: _categories,
        subcategories: _subcategories,
      });
      setData(supplier);
      setLoading(false);
    } else if (
      status === 'error' &&
      statusCategories === 'error' &&
      statusSubcategories === 'error'
    ) {
      setError(
        new Error(`
        supplier: ${errorSupplier?.message}
        categories: ${errorCategories?.message}
        subcategories: ${errorSubcategories?.message}
      `)
      );
      setLoading(false);
    }
  }, [
    doc,
    status,
    categories,
    subcategories,
    statusCategories,
    statusSubcategories,
    errorSupplier,
    errorCategories,
    errorSubcategories,
  ]);

  return { data, loading, error } as const;
}
