import { useState, useEffect } from 'react';
import { Category, Subcategory, Supplier } from '../models';
import firebase from '../lib/firebase/client';
import useFirestoreQuery from './useFirestoreQuery';

export default function useSuppliers() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Supplier[] | null>(null);
  const [error, setError] = useState<Error>(null);
  const {
    data: suppliersDocs,
    status: suppliersStatus,
    error: suppliersError,
  } = useFirestoreQuery<any[]>(firebase.firestore().collection('/suppliers'));
  const {
    data: categoriesDocs,
    status: categoriesStatus,
    error: categoriesError,
  } = useFirestoreQuery<any[]>(firebase.firestore().collection('/categories'));
  const {
    data: subcategoriesDocs,
    status: subcategoriesStatus,
    error: subcategoriesError,
  } = useFirestoreQuery<any[]>(
    firebase.firestore().collection('/subcategories')
  );

  useEffect(() => {
    if (
      suppliersStatus === 'success' &&
      categoriesStatus === 'success' &&
      subcategoriesStatus === 'success'
    ) {
      const categories = categoriesDocs?.map((doc) => Category.factory(doc));
      const subcategories = subcategoriesDocs?.map((doc) =>
        Subcategory.factory(doc)
      );
      const suppliers = suppliersDocs
        ?.map((doc) => Supplier.factory(doc))
        ?.map((supplier) => {
          supplier.categories = categories?.filter(
            (category) =>
              supplier?.categories?.filter((id: string) => category?.id === id)
                ?.length > 0
          );
          supplier.subcategories = subcategories?.filter(
            (subcategory) =>
              supplier?.subcategories?.filter(
                (id: string) => subcategory?.id === id
              )?.length > 0
          );
          return supplier;
        });
      setData(suppliers);
      setLoading(false);
    } else if (
      suppliersStatus === 'error' &&
      categoriesStatus === 'error' &&
      subcategoriesStatus === 'error'
    ) {
      setError(
        new Error(`
        supplier: ${suppliersError?.message}
        categories: ${categoriesError?.message}
        subcategories: ${subcategoriesError?.message}
      `)
      );
      setLoading(false);
    }
  }, [
    suppliersDocs,
    suppliersStatus,
    suppliersError,
    categoriesDocs,
    categoriesStatus,
    categoriesError,
    subcategoriesDocs,
    subcategoriesStatus,
    subcategoriesError,
  ]);

  return { data, loading, error };
}
