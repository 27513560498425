import { validateString } from 'avilatek-utils';
import { useState, useEffect, useRef } from 'react';
import { Request } from '../models';
import firebase from '../lib/firebase/client';

export default function useRequest(id: string) {
  const [loading, setLoading] = useState(true);
  const data = useRef<Request>(null);
  const [error, setError] = useState<Error>(null);
  useEffect(
    function queryFirebase() {
      const query = async () => {
        try {
          const categories = (
            await firebase.firestore().collection('/categories').get()
          ).docs.map((_doc) => _doc.data());
          const subcategories = (
            await firebase
              .firestore()
              .collection('/subcategories')
              .where('showOnBloop', '==', true)
              .get()
          ).docs.map((_doc) => _doc.data());
          const doc = (
            await firebase
              .firestore()
              .collection('/digitalRequestsBloop')
              .doc(id)
              .get()
          ).data();
          data.current = new Request({
            ...(doc ?? {}),
            categories:
              categories?.filter(
                (category) =>
                  doc?.categories?.filter((_id) => _id === category?.id)
                    ?.length > 0
              ) ?? [],
            subcategories:
              subcategories?.filter(
                (subcategory) =>
                  doc?.subcategories?.filter((_id) => _id === subcategory?.id)
                    ?.length > 0
              ) ?? [],
          });
          setLoading(false);
        } catch (err) {
          setError(new Error(err.message));
          setLoading(false);
        }
      };
      if (validateString(id)) {
        query();
      }
    },
    [id]
  );
  return { data: data.current, loading, error };
}
