import { useState, useEffect } from 'react';
import { Subcategory } from '../models';
import firebase from '../lib/firebase/client';

export default function useRequestCategories() {
  const [data, setData] = useState<Subcategory[]>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>(null);
  useEffect(() => {
    const u = firebase
      .firestore()
      .collection('/subcategories')
      .where('showOnBloop', '==', true)
      .onSnapshot(
        (snapshot) => {
          const docs = snapshot.docs.map((doc) => new Subcategory(doc.data()));
          setData(docs);
          setLoading(false);
        },
        (err) => {
          setError(err);
        }
      );
    return () => {
      if (u) {
        u();
      }
    };
  }, []);
  return { data, loading, error } as const;
}
