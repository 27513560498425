/* eslint-disable no-nested-ternary */
import React from 'react';
import firebase from '../lib/firebase/client';
import { Notification } from '../models';
import useFirestoreQuery from './useFirestoreQuery';

export default function useNotifications(_userId?: string) {
  const [notifications, setNotifications] = React.useState<
    Notification[] | null
  >(null);
  const [hasNew, setNew] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { data, status, error } = useFirestoreQuery<any[]>(
    firebase.firestore().collection('/notifications')
  );
  React.useEffect(() => {
    if (status === 'success') {
      const _notifications =
        data
          ?.map((doc) => Notification.factory(doc))
          ?.sort(({ date: a }, { date: b }) => b.getTime() - a.getTime()) ?? [];
      setNotifications(_notifications);
      const filtered = _notifications.filter(
        (notification) => !notification.seen
      );
      if (filtered.length > 0) {
        setNew(true);
      } else {
        setNew(false);
      }
      setLoading(false);
    } else if (status === 'error') {
      setLoading(false);
    }
  }, [data, status]);
  return { notifications, loading, error, pending: hasNew } as const;
}
