import { User } from '../models';
import { USER_PRIVILEGE } from '../config';
import firebase from '../lib/firebase/client';
import useFirestoreQuery from './useFirestoreQuery';

export default function useExecutives() {
  const { data, status, error } = useFirestoreQuery<User[]>(
    firebase
      .firestore()
      .collection('/users')
      .where('type', 'in', [USER_PRIVILEGE.EXECUTIVE, USER_PRIVILEGE.ADMIN])
  );
  return {
    data,
    loading: status !== 'success' && status !== 'error',
    error,
  } as const;
}
