import firebase from '../lib/firebase/client';
import useFirestoreQuery from './useFirestoreQuery';

export default function useDocument<T = any>(path: string) {
  if (!path) {
    throw new Error('[hook]: useDocument need a param `path`');
  }
  const { data, status, error } = useFirestoreQuery<T>(
    path ? firebase.firestore().doc(path) : null
  );
  return { data, loading: status !== 'success' && status !== 'error', error };
}
