import { useReducer, useEffect, useCallback } from 'react';
import firebase from '../lib/firebase/client';
import useMemoCompare from './useMemoCompare';

type TState<T = any> = {
  status: 'idle' | 'loading' | 'success' | 'error';
  data: T;
  error: Error;
};

type TActions =
  | { type: 'idle' }
  | { type: 'loading' }
  | { type: 'success'; payload: Record<string, any> }
  | { type: 'error'; payload: Error };

type TFirestoreReference =
  | firebase.firestore.CollectionReference
  | firebase.firestore.DocumentReference
  | firebase.firestore.Query;

function reducer(state: TState, action: TActions): TState {
  switch (action.type) {
    case 'idle':
      return { status: 'idle', data: undefined, error: undefined };
    case 'loading':
      return { status: 'loading', data: undefined, error: undefined };
    case 'success':
      return { status: 'success', data: action.payload, error: undefined };
    case 'error':
      return { status: 'error', data: undefined, error: action.payload };
    default:
      throw new Error('invalid action');
  }
}
const isCollectionRef = (
  ref: TFirestoreReference
): ref is firebase.firestore.CollectionReference =>
  ref instanceof firebase.firestore.CollectionReference;

const isDocumentRef = (
  ref: TFirestoreReference
): ref is firebase.firestore.DocumentReference =>
  ref instanceof firebase.firestore.DocumentReference;

const isQuery = (ref: TFirestoreReference): ref is firebase.firestore.Query =>
  ref instanceof firebase.firestore.Query;

// Get doc data and merge doc.id
function getDocData(
  doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
) {
  return doc.exists === true ? { id: doc.id, ...doc.data() } : null;
}
// Get array of doc data from collection
function getCollectionData(
  collection: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) {
  return collection.docs.map(getDocData);
}

export default function useFirestoreQuery<T = any>(
  query: TFirestoreReference
): TState<T> {
  // Our initial state
  // Start with an "idle" status if query is falsy, as that means hook consumer is
  // waiting on required data before creating the query object.
  // Example: useFirestoreQuery(uid && firestore.collection("profiles").doc(uid))
  const initialState: TState<T> = {
    status: query ? 'loading' : 'idle',
    data: undefined,
    error: undefined,
  };
  // Setup our state and actions
  const [state, dispatch] = useReducer(reducer, initialState);
  // Get cached Firestore query object with useMemoCompare (https://usehooks.com/useMemoCompare)
  // Needed because firestore.collection("profiles").doc(uid) will always being a new object reference
  // causing effect to run -> state change -> rerender -> effect runs -> etc ...
  // This is nicer than requiring hook consumer to always memoize query with useMemo.
  const compare = useCallback(
    (prev: TFirestoreReference) => {
      if (typeof prev === 'undefined' || prev === null) return false;
      if (typeof query === 'undefined' || query === null) return false;

      if (isCollectionRef(prev) && isCollectionRef(query)) {
        return query.isEqual(prev);
      }
      if (isDocumentRef(prev) && isDocumentRef(query)) {
        return query.isEqual(prev);
      }
      if (
        isQuery(prev) &&
        isQuery(query) &&
        !isDocumentRef(prev) &&
        !isCollectionRef(prev) &&
        !isDocumentRef(query) &&
        !isCollectionRef(query)
      ) {
        return query.isEqual(prev);
      }
      return Object.is(prev, query);
    },
    [query]
  );
  const queryCached = useMemoCompare(query, compare);
  useEffect(() => {
    // Return early if query is falsy and reset to "idle" status in case
    // we're coming from "success" or "error" status due to query change.
    if (!queryCached) {
      dispatch({ type: 'idle' });
      return;
    }
    dispatch({ type: 'loading' });
    // Subscribe to query with onSnapshot
    // Will unsubscribe on cleanup since this returns an unsubscribe function
    if (isCollectionRef(queryCached)) {
      return queryCached.onSnapshot(
        (response) => {
          // Get data for collection or doc
          const data = getCollectionData(response);
          dispatch({ type: 'success', payload: data });
        },
        (error) => {
          dispatch({ type: 'error', payload: error });
        }
      );
    }
    if (isDocumentRef(queryCached)) {
      return queryCached.onSnapshot(
        (response) => {
          // Get data for collection or doc
          const data = getDocData(response);
          dispatch({ type: 'success', payload: data });
        },
        (error) => {
          dispatch({ type: 'error', payload: error });
        }
      );
    }
    return queryCached.onSnapshot(
      (response) => {
        // Get data for collection or doc
        const data = getCollectionData(response);
        dispatch({ type: 'success', payload: data });
      },
      (error) => {
        dispatch({ type: 'error', payload: error });
      }
    );
  }, [queryCached]); // Only run effect if queryCached changes
  return state;
}
