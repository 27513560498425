import React from 'react';
import { User } from '../models';

interface AuthContextType {
  user?: User;
  setUser?: React.Dispatch<React.SetStateAction<User>>;
}

const AuthContext = React.createContext<AuthContextType>({});

export default AuthContext;
