import { useContext } from 'react';
import { ProjectContext } from '../context/ProjectContext';

export default function useProjectData() {
  const {
    categories,
    executives,
    projectManagers,
    subcategories,
    suppliers,
  } = useContext(ProjectContext);
  return {
    categories,
    executives,
    projectManagers,
    subcategories,
    suppliers,
  } as const;
}
